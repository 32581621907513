import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import useSize from "@react-hook/size";
import { bindActionCreators } from "redux";
import i18next from "i18next";
import { withRouter } from "react-router-dom";

import upsdkService from "common/services/upsdkService";
import { userLogin, userLogout } from "common/store/actions/auth";
import { likeItem, unlikeItem } from "common/store/actions/likes";
import { setLocationDialog } from "common/store/actions/ui";
import configSelector from "common/store/selectors/configSelector";
import itemSelector from "common/store/selectors/itemSelector";
import likesSelector from "common/store/selectors/likesSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import PlaceholderImage from "../../assets/images/placeholder.jpg";
import LazyImage from "../../common/LazyImage";
import AppButton from "../../common/button/index.component";
import Counter from "../../common/counter/index.component";
import DietIndicator from "../../common/diet-indicator/index.component";
import FavoriteIndicator from "../../common/favorite-indicator/index.component";
import RecommendationsWidget from "../../common/recommendations-widget/index.component";
import ItemDetailsShimmer from "./shimmer-view/index.component";
import ShareWidget from "../../common/share-widget/index.component";
import Typography from "../../common/typography/index.component";
import { BaseContext } from "../../context/BaseContext";
import CurrencyHelper from "common/helpers/currency.helper";
import fontHelper from "../../helpers/fontHelper";
import renderHelmet from "../../helpers/helmetHelper";
import i18n, { translateOptions } from "../../i18n";
import {
  COLOR_WHITE,
  COLOR_SCROLLBAR_GREY,
  COLOR_KOURNIKOVA,
} from "../../constants/colors.constants";
import graphqlService from "common/services/graphql/graphqlService";
import useRouteHook from "../../hooks/useRoute.hook";
import { Divider } from "@material-ui/core";

import "./index.component.scss";

function ItemDetails({
  t,
  config,
  selectedStore,
  match,
  cartItems,
  authToken,
  likeItem,
  unlikeItem,
  subLocality,
  likes,
  totalItemsCount,
  setLocationDialog,
}) {
  const componentRef = useRef();
  const [data, setData] = useState(null);
  const [viewPorts, setViewPort] = useState({});
  const [activeTab, setTab] = useState({ name: "description" });
  const [width, height] = useSize(componentRef);
  const [recommendations, setRecommendations] = useState([]);
  const {
    isQrModeEnabled,
    showLoginDialog,
    showItemCustomization,
    isMobileView,
  } = useContext(BaseContext);
  const [showFavLoading, setFavLoading] = useState(false);
  const { historyPush } = useRouteHook();

  // variables
  let isLiked;
  const item_id = parseInt(match.params.id);
  const location_id = selectedStore ? selectedStore.id : null;
  let cartItem = cartItems.find(
    (itemOfCart) => parseInt(itemOfCart?.id) === parseInt(item_id)
  );

  const primaryColor = configSelector.getPrimaryColor({ config });
  const showItemLikeButton = itemSelector.showItemLikeButton({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const showFoodTypeIndicator = itemSelector.showFoodTypeIndicator({ config });
  const showRecommendedItemsWidget = itemSelector.showRecommendedItemsWidget({
    config,
  });
  const selectedFontFamily = configSelector.getFontFamily({ config });
  const activeLanguage = i18next.language;

  if (item_id) {
    isLiked = likesSelector.isLiked({ likes, item_id: item_id });
  }

  useEffect(() => {
    if (!item_id) return;
    graphqlService.getItemById(item_id).then((data) => {
      setData(data);
    });

    if (isQrModeEnabled) return;
    const recommendedPromise = upsdkService.getRecommendedItems(
      [item_id],
      location_id
    );
    recommendedPromise.then((results) => {
      setRecommendations(results);
    });
  }, [item_id, location_id, activeLanguage, isQrModeEnabled]);

  useEffect(() => {
    if (width && height) {
      setViewPort(width < 768);
    }
  }, [width, height]);

  useEffect(() => {
    if (width && height) {
      setViewPort({
        width,
        height,
      });
    }
  }, [width, height]);

  const handleLikeItem = () => {
    if (!authToken) {
      showLoginDialog();
      return;
    }
    setFavLoading(true);
    setTimeout(() => {
      setFavLoading(false);
    }, 500);
    likeItem(data);
  };

  const handleUnlikeItem = () => {
    if (!authToken) {
      showLoginDialog();
      return;
    }
    setFavLoading(true);
    setTimeout(() => {
      setFavLoading(false);
    }, 500);
    unlikeItem(data);
  };

  const handleIncrementItemCount = () => {
    if (!subLocality && !selectedStore) {
      setLocationDialog();
      return;
    }
    if (data.raw_data.has_options) {
      showItemCustomization(data);
      return;
    }
    upsdkService.addToCart(data.raw_data);
  };

  const handleDecrementItemCount = () => {
    upsdkService.removeFromCart(data.raw_data);
  };

  const handleTabChange = (itemTab) => {
    setTab(itemTab);
  };

  const handleCustomizeClick = (event) => {
    event.stopPropagation();
    handleIncrementItemCount();
  };

  const handleViewCartClick = (event) => {
    event.stopPropagation();
    historyPush("/cart");
  };
  const renderDetailsTab = (itemTab) => {
    const { name } = itemTab;
    return (
      <div
        className="item-details-tab"
        onClick={() => handleTabChange(itemTab)}
        style={{
          borderBottom:
            activeTab.name === name ? `4px solid ${primaryColor}` : "none",
        }}
      >
        <Typography
          variant="h3"
          style={
            activeTab.name === name
              ? { webkitTextStroke: `0.8px ${primaryColor}` }
              : {}
          }
          className="item-tab-title"
          fontColor={
            activeTab.name === name ? primaryColor : secondaryTextColor
          }
        >
          {name}
        </Typography>
      </div>
    );
  };

  const DescriptionTabContent = () => {
    const selectedFontFamily = configSelector.getFontFamily({ config });
    const fontStyle = fontHelper(selectedFontFamily, "h3", "regular");
    return (
      <div
        style={{ ...fontStyle, color: secondaryTextColor }}
        className="description-tab-content"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    );
  };

  const OtherTabContent = () => {
    return (
      <div className="other-tab-content">
        {activeTab.key_values.map((value) => (
          <Typography
            variant="h3"
            weight="regular"
            className="other-tab-content-para"
            fontColor={secondaryTextColor}
          >
            <div dangerouslySetInnerHTML={{ __html: value.key }} />
            {value.value ? (
              <div dangerouslySetInnerHTML={{ __html: `${value.value}` }}></div>
            ) : null}
          </Typography>
        ))}
      </div>
    );
  };

  const renderDetails = () => {
    if (!data) return <ItemDetailsShimmer integrated={true} />;
    const {
      image,
      diet_type,
      name,
      price,
      extras,
      is_stock_available,
      likes,
      raw_data,
      current_stock,
    } = data;
    const itemCount = cartItem ? cartItem.quantity : 0;
    const showItemPrice = price && price >= 0;
    const showCustomization =
      raw_data && raw_data.has_options && is_stock_available && !itemCount;

    const renderCtaButtons = (
      <div className="button-wrapper">
        <div className="button-container">
          {!is_stock_available ? (
            <AppButton
              className="add-to-cart-cta"
              variant="contained"
              buttonColor={COLOR_KOURNIKOVA}
              onClickCallback={() => {}}
            >
              <Typography
                variant="h2"
                weight="regular"
                fontColor={primaryTextColor}
              >
                {t("buttons.outOfStock")}
              </Typography>
            </AppButton>
          ) : (
            <React.Fragment>
              {itemCount ? (
                <div className="count-exists-wrapper">
                  <AppButton
                    className="counter-btn"
                    variant="contained"
                    buttonColor={COLOR_WHITE}
                    onClickCallback={handleIncrementItemCount}
                  >
                    <Counter
                      itemCount={itemCount}
                      primaryColor={COLOR_WHITE}
                      fontColor={primaryColor}
                      countDecrementCallback={handleDecrementItemCount}
                      countIncrementCallback={handleIncrementItemCount}
                      currentStock={current_stock}
                    />
                  </AppButton>
                  <AppButton
                    className="view-cart-btn"
                    variant="contained"
                    buttonColor={primaryColor}
                    onClickCallback={handleIncrementItemCount}
                  >
                    <div
                      onClick={handleViewCartClick}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "70%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        weight="regular"
                        fontColor={COLOR_WHITE}
                      >
                        {totalItemsCount > 1
                          ? t("buttons.itemCountPlural", {
                              itemCount: totalItemsCount,
                            })
                          : t("buttons.itemCountSingular", {
                              itemCount: totalItemsCount,
                            })}
                      </Typography>
                      <Typography
                        variant="h2"
                        weight="bold"
                        fontColor={COLOR_WHITE}
                      >
                        {t("buttons.viewCart")}
                      </Typography>
                    </div>
                  </AppButton>
                </div>
              ) : (
                <AppButton
                  className="add-to-cart-cta"
                  variant="contained"
                  buttonColor={isMobileView ? primaryColor : COLOR_WHITE}
                  onClickCallback={handleIncrementItemCount}
                >
                  <Typography
                    variant="h2"
                    weight="bold"
                    fontColor={isMobileView ? COLOR_WHITE : primaryColor}
                  >
                    {isMobileView
                      ? !showCustomization
                        ? t("buttons.addToCart")
                        : t("itemCard.customize")
                      : t("buttons.add")}
                  </Typography>
                </AppButton>
              )}
              {!isMobileView && showCustomization && (
                <Typography
                  className="cutomize-button"
                  onClickCallback={handleCustomizeClick}
                  variant="h4"
                  weight="regular"
                  fontColor={COLOR_SCROLLBAR_GREY}
                >
                  {t("itemCard.customize")}
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );

    return (
      <div className="item-content">
        {renderHelmet({ title: data.name, description: data?.description })}
        <div className="image-wrapper">
          <LazyImage
            src={image ? image : PlaceholderImage}
            alt={name}
            className="item-img"
          />
          {showItemLikeButton && (
            <FavoriteIndicator
              config={config}
              likes={likes}
              isFavorite={isLiked}
              isLoading={showFavLoading}
              primaryColor={primaryColor}
              isLoggedIn={authToken ? true : false}
              primaryTextColor={primaryTextColor}
              markFavoriteCallback={handleLikeItem}
              markUnfavoriteCallback={handleUnlikeItem}
            />
          )}
          <div
            className="share-widget-wrapper"
            style={
              i18n.dir() === "rtl"
                ? { position: "absolute", top: "8px", left: "54px" }
                : { position: "absolute", top: "8px", right: "54px" }
            }
          >
            <ShareWidget isMobileView={isMobileView} />
          </div>
        </div>
        <div className="item-info">
          <div className="title-wrapper">
            <div className="product-name-wrapper">
              {showFoodTypeIndicator && (
                <DietIndicator
                  showDietName={!isMobileView}
                  dietType={diet_type}
                  size={{ width: 20, height: 20 }}
                />
              )}
              <div className="name-row">
                <Typography
                  variant="h1"
                  weight="bold"
                  className="product-name"
                  fontColor={primaryTextColor}
                >
                  {name}
                </Typography>
              </div>
            </div>
          </div>
          <div className="price-details-wrapper">
            <div className="pricing-details-wrapper">
              <Typography
                variant="h2"
                weight="bold"
                fontColor={primaryTextColor}
                className="discounted-item-price"
              >
                {showItemPrice
                  ? CurrencyHelper.format(price)
                  : raw_data && raw_data.price_descriptor}
              </Typography>
              {raw_data && raw_data.markup_price && is_stock_available ? (
                <Typography
                  variant="h4"
                  weight="regular"
                  className="original-item-price"
                >
                  {CurrencyHelper.format(raw_data.markup_price)}
                </Typography>
              ) : null}

              {showItemPrice && raw_data && raw_data.price_descriptor ? (
                <div
                  className="item-quantity"
                  dangerouslySetInnerHTML={{
                    __html: `${raw_data.price_descriptor}`,
                  }}
                  style={{
                    fontFamily: selectedFontFamily,
                  }}
                />
              ) : null}
            </div>
            {!isQrModeEnabled && renderCtaButtons}
          </div>
          <div className="item-extra-details-wrapper">
            {extras.length > 0 ? (
              <Fragment>
                <div className="item-details-tab-list">
                  <div
                    className="item-details-tab"
                    onClick={() => handleTabChange({ name: "description" })}
                    style={{
                      borderBottom:
                        activeTab.name === "description"
                          ? `4px solid ${primaryColor}`
                          : "none",
                    }}
                  >
                    <Typography
                      variant="h3"
                      style={
                        activeTab.name === "description"
                          ? { webkitTextStroke: `0.8px ${primaryColor}` }
                          : {}
                      }
                      className="item-tab-title"
                      fontColor={
                        activeTab.name === "description"
                          ? primaryColor
                          : secondaryTextColor
                      }
                    >
                      {t("itemDetails.description")}
                    </Typography>
                  </div>
                  {extras && extras.length
                    ? extras.map((itemTab) => renderDetailsTab(itemTab))
                    : null}
                </div>
                <Divider />
              </Fragment>
            ) : data?.description?.trim() ? (
              <Fragment>
                <div className="item-details-tab-list">
                  <div className="item-details-tab">
                    <Typography
                      variant="h3"
                      weight="bold"
                      className="item-tab-title"
                      fontColor={primaryTextColor}
                    >
                      {t("itemDetails.description")}
                    </Typography>
                  </div>
                </div>
                <Divider />
              </Fragment>
            ) : null}
            <div className="tab-details-wrapper">
              {extras.length === 0 || activeTab.name === "description" ? (
                <DescriptionTabContent />
              ) : (
                <OtherTabContent />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="item-details-wrapper" ref={componentRef}>
      <div className="container">
        <div className="item-details-container">
          <div className="item-details-section">
            {renderDetails()}
            {showRecommendedItemsWidget && (
              <RecommendationsWidget
                viewPorts={viewPorts}
                isMobileView={isMobileView}
                recommendations={recommendations}
                primaryColor={primaryColor}
                primaryTextColor={primaryTextColor}
                secondaryTextColor={secondaryTextColor}
                markFavoriteCallback={handleLikeItem}
                markUnfavoriteCallback={handleUnlikeItem}
                customizeItemClickCallback={handleCustomizeClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    upsdk: state.upsdk,
    subLocality: state.ui.subLocality,
    selectedStore: upsdkSelector.getStore(state),
    cartItems: upsdkSelector.getCart(state).items,
    totalItemsCount: upsdkSelector.getCart(state).totalItemsCount,
    authToken: upsdkSelector.getAuthHeader(state),
    likes: state.likes.data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      likeItem: likeItem,
      unlikeItem: unlikeItem,
      userLogin: userLogin,
      userLogout: userLogout,
      setLocationDialog: setLocationDialog,
    },
    dispatch
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions
  )(connect(mapStateToProps, mapDispatchToProps)(ItemDetails))
);
