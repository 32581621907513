import React, { useState, useEffect, Fragment, useContext } from "react";
import { animateScroll as scroll } from "react-scroll";

import {
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Radio,
} from "@material-ui/core";

import upsdkService from "common/services/upsdkService";
import graphqlService from "common/services/graphql/graphqlService";
import configSelector from "common/store/selectors/configSelector";
import itemSelector from "common/store/selectors/itemSelector";
import classNames from "classnames";

import CheckedCheckboxIcon from "../../assets/icons/checked-checkbox.icon";
import CrossIcon from "../../assets/icons/cross.icon";
import UncheckedCheckboxIcon from "../../assets/icons/unchecked-checkbox.icon";
import {
  ACTIVE_RADIO_ICON,
  INACTIVE_RADIO_ICON,
} from "../../constants/image.constants";
import CurrencyHelper from "common/helpers/currency.helper";
import AppButton from "../button/index.component";
import Counter from "../counter/index.component";
import DietIndicator from "../diet-indicator/index.component";
import Typography from "../typography/index.component";
import DataTransformer from "common/helpers/dataTransformer";
import { COLOR_SILVER_SAND } from "../../constants/colors.constants";
import { BaseContext } from "../../context/BaseContext";

import "./index.component.scss";

export default function ItemCustomization(props) {
  const [itemOptions, setItemOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [total, setTotal] = useState(0);
  const [itemCount, setItemCount] = useState(1);
  const [validationError, setValidationError] = useState({
    visible: false,
    message: "",
  });
  const { isQrModeEnabled } = useContext(BaseContext);

  // props
  const { t, open, item, config, selectedStore } = props;

  // variables
  const primaryColor = configSelector.getPrimaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const showFoodTypeIndicator = itemSelector.showFoodTypeIndicator({ config });
  const location_id = selectedStore ? selectedStore.id : null;

  // callbacks
  const { toggleItemCustomizationCallback } = props;

  useEffect(() => {
    if (!item || !open) {
      return;
    }
    setLoading(true);
    graphqlService
      .getItemOptions(item.id, location_id)
      .then((data) => {
        const itemOptionsRecieved = data;
        setItemOptions(itemOptionsRecieved);

        const defaultSelectedValues = [];
        itemOptionsRecieved.forEach((item) => {
          if (item.default) {
            item.options.length !== 0 &&
              item.options.forEach((modifiers) => {
                if (modifiers.current_stock !== 0 && modifiers.available) {
                  defaultSelectedValues.push(modifiers);
                }
              });
            setSelectedValues(defaultSelectedValues);
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
    setItemCount(1);
    setSelectedValues([]);

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, open]);

  useEffect(() => {
    if (!item || !open) {
      return;
    }
    let value = item.price;
    selectedValues.forEach((row) => {
      value += row.price;
    });
    setTotal(value * itemCount);

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, selectedValues, itemCount, itemOptions]);

  const handleClose = () => {
    toggleItemCustomizationCallback();
  };

  const handleAddClick = () => {
    //validate option groups
    if (!validateAll()) {
      return;
    }
    try {
      let ci = upsdkService.createCompoundItem({
        ...item.raw_data,
        option_groups: itemOptions,
      });
      //loop all the groups and selected options and add options to item;
      selectedValues.forEach((value) => {
        ci.addToOptions({ id: value.id, group_id: value.option_group_id });
      });
      if (ci.isValid().success === true) {
        let item = ci.getItemData();
        upsdkService.addToCart(item, itemCount);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateAll = () => {
    for (let index = 0; index < itemOptions.length; index++) {
      const group = itemOptions[index];
      if (!validateGroup(group)) {
        return false;
      }
    }
    return true;
  };

  const handleIncrement = () => {
    setItemCount(itemCount + 1);
  };

  const handleDecrement = () => {
    if (itemCount === 1) return;
    setItemCount(itemCount - 1);
  };

  const getOptionGroup = (option_group_id) => {
    const option_group = itemOptions.find(
      (item) => item?.id === option_group_id
    );
    const selected_options = selectedValues.filter(
      (row) => row?.option_group_id === option_group_id
    );

    return {
      id: option_group.id,
      slug: option_group.slug,
      title: option_group.title,
      max: option_group.max_selectable,
      min: option_group.min_selectable,
      selected_options: selected_options,
    };
  };

  const validateGroup = (group) => {
    const option_group = getOptionGroup(group.id);
    const selected_count = option_group.selected_options
      ? option_group.selected_options.length
      : 0;

    if (option_group.max === -1) {
      return true;
    }
    const valid = !(
      selected_count < option_group.min || selected_count > option_group.max
    );
    if (!valid) {
      let validationMessage = `${getMessage(group)} from ${option_group.title}`;
      scrollToSection(option_group.id);
      setValidationError({ visible: true, message: validationMessage });
    }
    return valid;
  };

  const addNestedOptions = (value) => {
    let options = itemOptions;
    const option_group = getOptionGroup(value.option_group_id);

    //clear all options belongs to this option
    options = options.filter(
      (group) => group?.parent_group_id !== option_group?.id
    );

    if (value.nested_options_group) {
      const optionGroupIndex = options.findIndex((optionGroup, index) => {
        if (optionGroup.id === value.option_group_id) {
          return true;
        }

        return false;
      });

      // All items are getting added to top level only,
      // make sure to to find the index of option group
      // and add nested options at next index of it.
      let nestedOptions = [];
      value.nested_options_group.forEach((group) => {
        nestedOptions.push({
          ...group,
          parent_group_id: option_group.id,
          parent_option_id: value.id,
        });
      });
      options.splice(optionGroupIndex + 1, 0, ...nestedOptions);
    }
    setItemOptions(options);
  };

  const addSelectedValue = (value) => {
    let data = selectedValues;
    let options = itemOptions;
    const option_group = getOptionGroup(value.option_group_id);
    const exists = selectedValues.find(
      (selectedValue) => selectedValue?.id === value?.id
    );
    if (exists) {
      if (exists.nested_options_group) {
        options = options.filter((row) => row?.parent_group_id !== value?.id);
        exists.nested_options_group.forEach((group) => {
          group.options.forEach((option) => {
            data = data.filter((item) => item !== option);
          });
        });
      }
      data = data.filter((item) => item !== exists);
      setSelectedValues(data);
      setItemOptions(options);
      return;
    }

    //clear all selected options that belongs to this group
    if (option_group.max === 1) {
      data.forEach((row, index) => {
        if (row.option_group_id === option_group.id) {
          data.length = index;
        }
      });
      data.push(value);
    } else {
      data = data.filter((row) => row?.id !== value?.id);
      data.push(value);
    }
    setSelectedValues(data);
  };

  const handleRadioChange = (value) => {
    addSelectedValue(value);
    addNestedOptions(value);
    setValidationError({ visible: false, message: "" });
    setTimeout(() => {
      if (value.nested_options_group && value.nested_options_group.length) {
        const nestedOptionGroup = value.nested_options_group[0];
        scrollToSection(nestedOptionGroup.id);
      }
    }, 0);
  };

  const handleCheckboxChange = (value) => {
    addSelectedValue(value);
    addNestedOptions(value);
    setValidationError({ visible: false, message: "" });
  };

  const isSelected = (value) => {
    return selectedValues.find(
      (selectedValue) =>
        selectedValue?.id === value?.id &&
        selectedValue?.option_group_id === value?.option_group_id
    );
  };

  const AddOnList = () => (
    <div className="add-on-list">
      {itemOptions &&
        itemOptions.map((addOn, index) => (
          <RenderOptions key={index} addOn={addOn} />
        ))}
    </div>
  );

  const getMessage = (addOn) => {
    let message = "";
    message = t("common.selectAsMany");
    if (
      addOn.min_selectable === addOn.max_selectable &&
      addOn.max_selectable > 0
    ) {
      message = t("common.selectMaxSelectable", {
        maxSelectable: addOn.max_selectable,
      });
    } else if (addOn.min_selectable < addOn.max_selectable) {
      if (addOn.min_selectable === 0) {
        message = t("common.selectUpto", {
          uptoOptions: Math.min(addOn.max_selectable, addOn.options.length),
        });
      } else if (addOn.min_selectable > 0) {
        message = t("common.selectOptionRange", {
          minSelectable: addOn.min_selectable,
          maxSelectable: addOn.max_selectable,
        });
      }
    }

    return message;
  };

  const RenderOptions = ({ addOn }) => {
    let groupMessage = getMessage(addOn);
    return (
      <div className="add-on">
        <div className="add-on-title" id={addOn.id}>
          <Typography
            variant="h4"
            weight="bold"
            className="title-text"
            fontColor={secondaryColor}
          >
            {addOn.title}
          </Typography>
          <Typography variant="para" weight="bold" className="group-message">
            {groupMessage}
          </Typography>
        </div>
        <AddOnValueList
          values={addOn.options}
          isMultiple={addOn.max_selectable > 1 || addOn.max_selectable === -1}
        />
      </div>
    );
  };

  const scrollToSection = (sectionId) => {
    const sectionEle = document.getElementById(sectionId);
    scroll.scrollTo(sectionEle.offsetTop - 100, {
      containerId: "scrollable-container",
    });
  };

  const AddOnValueList = ({ values, isMultiple }) => {
    return (
      <FormGroup className="add-on-value-list">
        {values.map((value, index) => (
          <Fragment key={index}>{renderAddOnValue(isMultiple, value)}</Fragment>
        ))}
      </FormGroup>
    );
  };

  const renderAddOnValue = (isMultiple, value) => {
    const { id, title, price, current_stock, available, food_type } = value;
    const isDisabled = current_stock === 0 || !available;

    if (isQrModeEnabled) {
      return (
        <div className="qr-code-menu-add-on-row">
          <div className="qr-code-menu-add-on-label">
            {showFoodTypeIndicator && (
              <DietIndicator
                {...props}
                className={classNames("add-on-diet-indicator", {
                  "add-on-diet-indicator-disabled": isDisabled,
                })}
                dietType={DataTransformer.getDietType(food_type)}
                size={{ width: 16, height: 16 }}
              />
            )}
            <Typography
              variant="h4"
              weight="regular"
              className="add-on-text"
              fontColor={isDisabled ? COLOR_SILVER_SAND : secondaryTextColor}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              weight="regular"
              className="add-on-price"
              fontColor={isDisabled ? COLOR_SILVER_SAND : secondaryTextColor}
            >
              {isDisabled
                ? t("buttons.outOfStock")
                : CurrencyHelper.format(price)}
            </Typography>
          </div>
        </div>
      );
    }

    if (isMultiple) {
      return (
        <div className="add-on-value-row">
          <FormControlLabel
            key={id}
            checked={false}
            classes={{ root: "add-on-value", label: "add-on-value-label" }}
            control={
              <Checkbox
                checked={isSelected(value)}
                icon={
                  <UncheckedCheckboxIcon
                    className="checkbox-icon"
                    fill={isDisabled ? "#F5F5F5" : "#000"}
                    outline={isDisabled ? "#F5F5F5" : "#000"}
                  />
                }
                checkedIcon={<CheckedCheckboxIcon className="checkbox-icon" />}
                onChange={() => handleCheckboxChange(value)}
                name={name}
                disabled={isDisabled}
              />
            }
            label={
              <div className="add-on-value-label-wrapper">
                {showFoodTypeIndicator && (
                  <DietIndicator
                    {...props}
                    className={classNames("add-on-diet-indicator", {
                      "add-on-diet-indicator-disabled": isDisabled,
                    })}
                    dietType={DataTransformer.getDietType(food_type)}
                    size={{ width: 16, height: 16 }}
                  />
                )}
                <Typography
                  variant="h4"
                  weight="regular"
                  className="add-on-text"
                  fontColor={
                    isDisabled ? COLOR_SILVER_SAND : secondaryTextColor
                  }
                >
                  {title}
                </Typography>
                <Typography
                  variant="h4"
                  weight="regular"
                  className="add-on-price"
                  fontColor={
                    isDisabled ? COLOR_SILVER_SAND : secondaryTextColor
                  }
                >
                  {isDisabled
                    ? t("buttons.outOfStock")
                    : CurrencyHelper.format(price)}
                </Typography>
              </div>
            }
          />
        </div>
      );
    }

    return (
      <div className="add-on-value-row">
        <FormControlLabel
          key={id}
          classes={{ root: "add-on-value", label: "add-on-value-label" }}
          checked={false}
          control={
            <Radio
              id={id}
              checked={isSelected(value)}
              icon={
                <img
                  style={
                    isDisabled
                      ? { opacity: 0.4, filter: "alpha(opacity=40)" }
                      : {}
                  }
                  src={INACTIVE_RADIO_ICON}
                  className="radio-icon"
                  alt="Inactive Radio Icon"
                />
              }
              checkedIcon={
                <img
                  src={ACTIVE_RADIO_ICON}
                  className="radio-icon"
                  alt="Active Radio Icon"
                />
              }
              onChange={() => handleRadioChange(value)}
              disabled={isDisabled}
            />
          }
          label={
            <div className="add-on-value-label-wrapper">
              {showFoodTypeIndicator && (
                <DietIndicator
                  {...props}
                  className={classNames("add-on-diet-indicator", {
                    "add-on-diet-indicator-disabled": isDisabled,
                  })}
                  dietType={DataTransformer.getDietType(food_type)}
                  size={{ width: 16, height: 16 }}
                />
              )}
              <Typography
                variant="h4"
                weight="regular"
                className="add-on-text"
                fontColor={isDisabled ? COLOR_SILVER_SAND : secondaryTextColor}
              >
                {title}
              </Typography>
              <Typography
                variant="h4"
                weight="regular"
                className="add-on-price"
                fontColor={isDisabled ? COLOR_SILVER_SAND : secondaryTextColor}
              >
                {isDisabled
                  ? t("buttons.outOfStock")
                  : CurrencyHelper.format(price)}
              </Typography>
            </div>
          }
        />
      </div>
    );
  };

  if (!item) {
    return null;
  }

  const { name, diet_type, description, current_stock } = item;

  if (loading) {
    return <div>Loading placeholder</div>;
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      classes={{
        root: "item-customization-dialog-root",
        container: "item-customization-dialog-container",
        paper: "item-customization-dialog-paper",
      }}
      disableScrollLock={true}
    >
      <div className="item-customization-form">
        <div className="dialog-header">
          <div className="title-wrapper">
            {showFoodTypeIndicator && (
              <DietIndicator
                {...props}
                dietType={diet_type}
                size={{ width: 16, height: 16 }}
              />
            )}
            <Typography
              variant="h1"
              weight="bold"
              className="form-heading"
              fontColor={primaryTextColor}
            >
              {name}
            </Typography>
          </div>
          <CrossIcon
            fill="#A3A3A4"
            size={{ width: 16, heigth: 16 }}
            className="close-dialog-icon"
            onClickCallback={toggleItemCustomizationCallback}
          />
        </div>
        <div className="dialog-content" id="scrollable-container">
          {description && description.length ? (
            <Typography
              variant="h4"
              weight="regular"
              className="dialog-sub-heading"
              fontColor={secondaryTextColor}
            >
              {description}
            </Typography>
          ) : null}
          <AddOnList />
        </div>
        {!isQrModeEnabled && (
          <div className="dialog-footer">
            {validationError.visible && (
              <div className="error-wrapper">
                <Typography
                  variant="h4"
                  weight="bold"
                  className="validation-error-msg"
                >
                  {validationError.message}
                </Typography>
              </div>
            )}
            <Counter
              {...props}
              itemCount={itemCount}
              primaryColor={primaryColor}
              countDecrementCallback={() => handleDecrement()}
              countIncrementCallback={() => handleIncrement()}
              currentStock={current_stock}
            />
            <AppButton
              fullWidth
              variant="contained"
              className="save-button"
              onClickCallback={handleAddClick}
              buttonColor={primaryColor}
            >
              <Typography variant="h3" weight="bold" fontColor="white">
                {t("itemCustomization.add")}
              </Typography>
              <Typography
                variant="h4"
                weight="regular"
                fontColor="white"
                className="save-button-total"
              >
                {` (${CurrencyHelper.format(total)})`}
              </Typography>
            </AppButton>
          </div>
        )}
      </div>
    </Dialog>
  );
}
