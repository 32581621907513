import React, { useState, useEffect, useContext } from "react";

import dayjs from "dayjs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translateOptions } from "../../../i18n";
import { withTranslation, useTranslation } from "react-i18next";
import { bright_90, increaseBrightness } from "common/helpers/adjustColor";
import {
  setDeliveryTimeCallback,
  setSelectedSlotCallback,
  setSelectedType,
} from "common/store/actions/deliveryTime";
import deliveryTimeService from "common/services/deliveryTime/deliveryTimeService";
import getDeliveryDateTime from "common/services/deliveryTime/helpers/getDeliveryDateTime";
import configSelector from "common/store/selectors/configSelector";
import { BaseContext } from "../../../context/BaseContext";
import CalenderIcon from "../../../assets/icons/calender.icon";
import ClockIcon from "../../../assets/icons/clock.icon";
import DateField from "../../../common/fields/date-picker/index.component";
import SelectField from "../../../common/fields/select-field/index.component";
import TimeField from "../../../common/fields/time-picker/index.component";
import Typography from "../../../common/typography/index.component";
import InactiveRadioBtn from "../../../assets/icons/landing-screen-radio-inactive";
import ActiveRadioBtn from "../../../assets/icons/landing-screen-radio-active";
import AppButton from "../../../common/button/index.component";
import {
  COLOR_WHITE,
  COLOR_GREY_TWO,
} from "../../../constants/colors.constants";
import useRouteHook from "../../../hooks/useRoute.hook";

import "./index.component.scss";

function DeliveryModeWidget({
  setDeliveryTimeCallback,
  setSelectedSlotCallback,
  setSelectedType,
  config,
  selectedStore,
  bizInfo,
  primaryTextColor,
  fulfilmentType,
  primaryColor,
  secondaryTextColor,
  secondaryColor,
  selectedType,
  deliveryTime,
  selectedSlot,
  setOpen,
  landingPage,
  closeDesktopDialog,
}) {
  const { menuRoute, isMobileView } = useContext(BaseContext);

  const [showDeliverNow, setShowDeliverNow] = useState(true);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [openingClosing, setOpeningClosing] = useState(null);
  const [offsetTime, setOffsetTime] = useState(0);
  const [openTimeSlotsDropdown, setTimeSlotDropdown] = useState(false);
  const { historyPush } = useRouteHook();

  // variables
  let timeSlotsEnabled = configSelector.getEnableTimeSlots({ config });
  const hideDeliverNowAfterOffset = configSelector.getHideDeliverNowAfterOffset(
    { config }
  );
  const { t } = useTranslation();

  useEffect(() => {
    let isFuture = selectedDate ? selectedDate?.isAfter(dayjs(), "day") : false;
    let finalDate = selectedDate;
    if (isFuture) {
      finalDate = finalDate.set("hour", "00").set("minute", "00");
    } else {
      finalDate = dayjs();
    }
    let slots = deliveryTimeService.getAvailableSlots(finalDate);
    let openingClosing =
      deliveryTimeService.getOpeningClosingForDate(finalDate);
    if (openingClosing) {
      setSelectedTime(openingClosing.startTime);
    }
    setAvailableSlots(slots);
    setOpeningClosing(openingClosing);
  }, [selectedDate]);

  useEffect(() => {
    if (!availableSlots.length) {
      setSelectedTimeSlot(null);
    } else if (selectedSlot) {
      setSelectedTimeSlot(selectedSlot);
    } else {
      setSelectedTimeSlot(availableSlots[0]);
    }

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSlots]);

  useEffect(() => {
    let offset = deliveryTimeService.getOffsetTime();
    setOffsetTime(offset);

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  const isDeliveryDateTimeValid = () => {
    return dayjs().isBefore(deliveryTime);
  };

  const deliveryDataSelected = () => {
    if (!selectedDate) {
      return;
    }
    const deliveryDateTime = getDeliveryDateTime({
      time: selectedTime,
      date: selectedDate,
      slot: timeSlotsEnabled ? selectedSlot : null,
    });

    setDeliveryTimeCallback(deliveryDateTime);
    setSelectedSlotCallback(selectedTimeSlot);
    setOpen(false);
    closeDesktopDialog();
    if (landingPage) {
      historyPush(menuRoute);
    }
  };

  useEffect(() => {
    let showDeliverNow =
      deliveryTimeService.isDeliverPickupNowAvailable().success;
    if (hideDeliverNowAfterOffset) {
      showDeliverNow = showDeliverNow && offsetTime < hideDeliverNowAfterOffset;
    }
    setShowDeliverNow(showDeliverNow);
    if (!showDeliverNow) {
      selectDeliveryMode("deliver_later");
    }
    if (
      deliveryTime &&
      isDeliveryDateTimeValid() &&
      selectedType !== "deliver_now"
    ) {
      setSelectedDate(dayjs(deliveryTime));
    } else {
      let nextAvailable = deliveryTimeService.getNextAvailableDateTime();
      if (nextAvailable) {
        setSelectedDate(nextAvailable.startTime);
        setSelectedTime(nextAvailable.openingTime);
      }
    }

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore, bizInfo, offsetTime, deliveryTime, selectedType]);

  const handleDisableDate = (date) => {
    let slots = deliveryTimeService.getAvailableSlots(date);
    if (!slots || !slots.length) {
      return true;
    }
  };

  const toggleTimeSlotDropdown = () => {
    setTimeSlotDropdown(!openTimeSlotsDropdown);
  };

  const selectDeliveryMode = (type) => {
    if (type === "deliver_later") {
      if (timeSlotsEnabled) {
        setSelectedType("deliver_at");
      } else {
        setSelectedType(type);
      }
    } else {
      setSelectedType(type);
    }
  };

  const selectDeliveryDate = (date) => {
    setSelectedDate(date);
  };

  const selectDeliveryTime = (time) => {
    setSelectedTime(time);
  };

  const selectDeliverySlot = (slot) => {
    setSelectedTimeSlot(slot);
  };

  // variables
  let deliveryTypeNowText = t("checkout.deliverNow");
  let deliveryTypeLaterText = t("checkout.deliverLater");
  let orderWaitingText = t("checkout.deliveryOrderWaitingText");

  if (fulfilmentType === "pickup") {
    deliveryTypeNowText = t("checkout.pickupNow");
    deliveryTypeLaterText = t("checkout.pickupLater");
    orderWaitingText = t("checkout.pickupOrderWaitingText");
  }

  const renderDeliverNowWidget = () => {
    return (
      <div className="deliver-now-widget">
        <Typography
          variant="h3"
          weight={"semiBold"}
          className="order-waiting-text"
          fontColor={secondaryTextColor}
        >
          {orderWaitingText}
        </Typography>
        <Typography
          variant="h3"
          weight={isMobileView ? "semiBold" : "bold"}
          className="order-waiting-time"
          fontColor={secondaryTextColor}
        >
          {t("checkout.waitingTime", {
            waitingTime: Math.round(offsetTime / 1000 / 60),
          })}
        </Typography>
      </div>
    );
  };

  const renderDeliverLaterWidget = () => {
    const isDeliverLaterAvailable =
      deliveryTimeService.isDeliverPickupLaterAvailable();
    const { datePicker, maxDeliveryDate, minDeliveryDate } =
      isDeliverLaterAvailable.data;

    const deliveryOpenTime = openingClosing ? openingClosing.startTime : null;
    const deliveryEndTime = openingClosing ? openingClosing.endTime : null;
    let errorMessage = t("checkout.storeClosedForPickup");

    return (
      <div className="deliver-later-widget">
        {datePicker && (
          <DateField
            name="Date"
            shouldDisableDate={handleDisableDate}
            label={
              <Typography
                variant="h4"
                weight="bold"
                fontColor={primaryTextColor}
              >
                {t("checkout.date")}
              </Typography>
            }
            minDate={minDeliveryDate}
            selectedDate={selectedDate}
            maxDate={maxDeliveryDate}
            onDateChange={selectDeliveryDate}
            inputIcon={<CalenderIcon className="field-icon" />}
            primaryTextColor={primaryTextColor}
            iconBackgroundColor={increaseBrightness(secondaryColor, bright_90)}
            config={config}
          />
        )}
        {selectedDate && !availableSlots.length && (
          <Typography
            variant="h4"
            weight="regular"
            className="available-time-slot"
          >
            {errorMessage}
          </Typography>
        )}

        {selectedDate && availableSlots && !!availableSlots.length && (
          <>
            <Typography
              variant="h4"
              weight="regular"
              className="available-time-slot"
            >
              {t("checkout.availaleTimeSlot", {
                startTime: dayjs(deliveryOpenTime).format("hh:mm A"),
                endTime: dayjs(deliveryEndTime).format("hh:mm A"),
              })}
            </Typography>
            <TimeField
              name="Time"
              label={
                <Typography
                  variant="h4"
                  weight="bold"
                  fontColor={primaryTextColor}
                >
                  {t("checkout.time")}
                </Typography>
              }
              selectedTime={selectedTime}
              minDate={deliveryOpenTime}
              maxDate={deliveryEndTime}
              onTimeChange={selectDeliveryTime}
              inputIcon={<ClockIcon className="field-icon" />}
              primaryTextColor={primaryTextColor}
              iconBackgroundColor={increaseBrightness(
                secondaryColor,
                bright_90
              )}
            />
          </>
        )}
      </div>
    );
  };

  const renderDeliverTimeSlotWidget = () => {
    const isDeliverLaterAvailable =
      deliveryTimeService.isDeliverPickupLaterAvailable();
    const { datePicker, maxDeliveryDate, minDeliveryDate } =
      isDeliverLaterAvailable.data;

    const filterAndUpdateSlot = (obj) => {
      let slots = availableSlots.filter(
        (slot) => slot.displayTitle === obj.target.value
      );
      if (slots.length > 0) {
        selectDeliverySlot(slots[0]);
      }
    };

    return (
      <div className="deliver-timeslot-widget">
        {datePicker && (
          <DateField
            name="Date"
            label={
              <Typography
                variant="h4"
                weight="bold"
                fontColor={primaryTextColor}
              >
                {t("checkout.date")}
              </Typography>
            }
            minDate={minDeliveryDate}
            selectedDate={selectedDate ? selectedDate : dayjs()}
            maxDate={maxDeliveryDate}
            onDateChange={selectDeliveryDate}
            inputIcon={<CalenderIcon className="field-icon" />}
            primaryTextColor={primaryTextColor}
            iconBackgroundColor={increaseBrightness(secondaryColor, bright_90)}
            config={config}
          />
        )}
        {selectedDate && availableSlots && availableSlots.length ? (
          <SelectField
            name="Timeslot"
            label={
              <Typography
                variant="para"
                weight="bold"
                fontColor={primaryTextColor}
              >
                {t("checkout.timeSlot")}
              </Typography>
            }
            open={openTimeSlotsDropdown}
            defaultValue={
              selectedTimeSlot ? selectedTimeSlot.displayTitle : null
            }
            inputIcon={<ClockIcon className="field-icon" />}
            primaryTextColor={primaryTextColor}
            iconBackgroundColor={increaseBrightness(secondaryColor, bright_90)}
            options={availableSlots.map((slot) => ({
              title: slot.displayTitle,
              value: slot.displayTitle,
            }))}
            onChange={(obj) => filterAndUpdateSlot(obj)}
            toggleTimeSlotDropdownCallback={toggleTimeSlotDropdown}
          />
        ) : (
          <Typography
            variant="h4"
            weight="regular"
            className="available-time-slot"
          >
            {t("checkout.noTimeslotForDateError")}
          </Typography>
        )}
      </div>
    );
  };

  const renderDeliveryModeTabList = () => {
    return (
      <ul className="delivery-mode-list">
        {!!showDeliverNow && (
          <li
            className="delivery-mode-button"
            style={{
              borderColor: isDeliverNowView ? primaryColor : COLOR_GREY_TWO,
            }}
            onClick={() => selectDeliveryMode("deliver_now")}
          >
            <div className="radio-title-wrapper">
              <Typography variant="h2" fontColor={primaryColor} weight="bold">
                {deliveryTypeNowText}
              </Typography>
              {isDeliverNowView ? (
                <ActiveRadioBtn
                  innerFill={primaryColor}
                  border={primaryColor}
                />
              ) : (
                <InactiveRadioBtn stroke={COLOR_GREY_TWO} />
              )}
            </div>
            {renderDeliverNowWidget()}
          </li>
        )}

        <li
          className="delivery-mode-button"
          style={{
            borderColor:
              isDeliverLaterView || isDeliverTimeSlotView
                ? primaryColor
                : COLOR_GREY_TWO,
          }}
          onClick={() => selectDeliveryMode("deliver_later")}
        >
          <div className="radio-title-wrapper">
            <Typography variant="h2" weight="bold" fontColor={primaryColor}>
              {deliveryTypeLaterText}
            </Typography>
            {isDeliverLaterView || isDeliverTimeSlotView ? (
              <ActiveRadioBtn innerFill={primaryColor} border={primaryColor} />
            ) : (
              <InactiveRadioBtn stroke={COLOR_GREY_TWO} />
            )}
          </div>
          {isDeliverLaterView
            ? renderDeliverLaterWidget()
            : isDeliverTimeSlotView
            ? renderDeliverTimeSlotWidget()
            : null}
        </li>
      </ul>
    );
  };

  const isDeliverNowView = selectedType === "deliver_now";
  const isDeliverLaterView = selectedType === "deliver_later";
  const isDeliverTimeSlotView = selectedType === "deliver_at";

  return (
    <div className="delivery-mode-widget-wrapper">
      {renderDeliveryModeTabList()}

      <AppButton
        isDisabled={!(selectedDate && availableSlots && availableSlots.length)}
        variant="contained"
        className={landingPage ? "continue-cta-landing" : "continue-cta"}
        buttonColor={primaryColor}
        onClickCallback={deliveryDataSelected}
      >
        <Typography variant="h2" fontColor={COLOR_WHITE}>
          {t("authDialog.continue")}
        </Typography>
      </AppButton>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    selectedType: state.deliveryTime.delivery.selectedType,
    deliveryTime: state.deliveryTime.delivery.deliveryTime,
    selectedSlot: state.deliveryTime.delivery.selectedTimeSlot,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDeliveryTimeCallback: setDeliveryTimeCallback,
      setSelectedSlotCallback: setSelectedSlotCallback,
      setSelectedType: setSelectedType,
    },
    dispatch
  );

export default withTranslation(
  ["translations"],
  translateOptions
)(connect(mapStateToProps, mapDispatchToProps)(DeliveryModeWidget));
