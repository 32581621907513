import React, { useState } from "react";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import itemSelector from "common/store/selectors/itemSelector";
import FavouriteContainedIcon from "../../assets/icons/favorite-contained.icon";
import FavouriteOutlinedIcon from "../../assets/icons/favorite-outlined.icon";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function FavoriteIndicator({
  likes,
  isFavorite,
  isLoggedIn,
  isLoading,
  primaryColor,
  config,
  markFavoriteCallback,
  markUnfavoriteCallback,
}) {
  // variables
  const showLikesCount = itemSelector.showLikesCount({ config });
  const [currentLike, setCurrentLike] = useState(likes || 0);
  const [currentFavorite, setCurrentFavorite] = useState(isFavorite);

  const { i18n } = useTranslation();
  const direction = i18n.dir();

  const handleFavoriteClick = (likeCount) => {
    if (isLoggedIn) {
      setCurrentLike(likeCount + 1);
      setCurrentFavorite(true);
    }
    markFavoriteCallback();
  };

  const handleUnFavoriteClick = (likeCount) => {
    if (isLoggedIn) {
      setCurrentLike(likeCount - 1);
      setCurrentFavorite(false);
    }
    markUnfavoriteCallback();
  };

  const handleClick = (evt, fav, likeCount) => {
    evt.stopPropagation();
    fav ? handleUnFavoriteClick(likeCount) : handleFavoriteClick(likeCount);
  };

  const Loader = () => (
    <CircularProgress
      classes={{ root: "button-loader" }}
      style={{ width: 18, height: 18 }}
    />
  );

  const getLikedIcon = (fav) => {
    if (fav)
      return (
        <FavouriteContainedIcon fill={primaryColor} className="favorite-icon" />
      );

    return (
      <FavouriteOutlinedIcon fill={primaryColor} className="favorite-icon" />
    );
  };

  return (
    <div
      className={classNames("favorite-indicator-wrapper", {
        "icon-position-ltr": direction === "ltr",
        "icon-position-rtl": direction === "rtl",
      })}
    >
      {/* for loading screen */}
      {isLoading && (
        <div className="icon-wrapper">
          <Loader />
        </div>
      )}

      {/* if favourite is selected */}
      {!isLoading && (
        <div
          className="icon-wrapper"
          onClick={(evt) => handleClick(evt, currentFavorite, currentLike)}
        >
          {getLikedIcon(currentFavorite)}
          {showLikesCount && currentLike > 0 && (
            <Typography
              variant="h4"
              weight="regular"
              className="likes-count"
              fontColor={primaryColor}
            >
              {currentLike}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
