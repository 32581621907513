import React, { Fragment, Suspense, lazy, useEffect, useState } from "react";
import RTLSupport from "./styles/rtl-support";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ltrTheme, rtlTheme } from "./styles/theme";
import { BrowserRouter } from "react-router-dom";
import MerakiEditorListener from "./common/meraki-editor-listener";
import { ApolloProvider } from "@apollo/client";
import Routes from "./routes/online-ordering.routes";
import { useTranslation } from "react-i18next";
import { client } from "common/services/graphql/index";
import { ORDERING_TYPE_ONLINE } from "./constants/enums.constants";
import QRCodeOrderingRoutes from "./routes/qr-code-ordering.routes";

const MixpanelHandler = lazy(() =>
  import(
    "./common/mixpanel-handler/index.component" /* webpackChunkName: "MixpanelHandler" */
  )
);

function App({ orderingType }) {
  const [theme, setTheme] = useState(ltrTheme);
  const { i18n } = useTranslation();
  const direction = i18n.dir();

  useEffect(() => {
    if (direction === "ltr") {
      setTheme(ltrTheme);
    } else {
      setTheme(rtlTheme);
    }
  }, [direction]);

  return (
    <Fragment>
      <RTLSupport>
        <MuiThemeProvider theme={theme}>
          <Suspense fallback={null}>
            <MixpanelHandler />
          </Suspense>
          <BrowserRouter>
            <ApolloProvider client={client}>
              <MerakiEditorListener />

              {orderingType === ORDERING_TYPE_ONLINE ? (
                <Routes />
              ) : (
                <QRCodeOrderingRoutes />
              )}
            </ApolloProvider>
          </BrowserRouter>
        </MuiThemeProvider>
      </RTLSupport>
    </Fragment>
  );
}

export default App;
