import { useLocation, useHistory, NavLink, Link } from "react-router-dom";
import configSelector from "common/store/selectors/configSelector";

export default function useRouteHook() {
  const config = window?.ROOT_CONFIG || {};
  const subPath = configSelector.getSubPath({ config });
  const { search, pathname } = useLocation();
  const history = useHistory();

  const generatePath = (to) => {
    if (subPath) {
      return "/" + subPath + to + "/" + search;
    } else {
      return to + "/" + search;
    }
  };

  const historyPush = (to) => {
    const path = generatePath(to);
    history.push(path);
  };

  const historyGoBack = () => {
    history.goBack();
  };

  const CustomNavLink = ({
    children,
    className,
    activeStyle,
    activeClassName,
    to,
    style,
  }) => {
    return (
      <NavLink
        className={className}
        activeStyle={activeStyle}
        style={style}
        activeClassName={activeClassName}
        to={generatePath(to)}
      >
        {children}
      </NavLink>
    );
  };

  const CustomLink = ({ children, to, target }) => {
    return (
      <Link to={generatePath(to)} target={target}>
        {children}
      </Link>
    );
  };

  return {
    historyPush,
    historyGoBack,
    pathname,
    CustomNavLink,
    CustomLink,
  };
}
