import clone from "./deepClone";
import Item from "../store/models/Item";

/**
 * All data transforming operations
 */
export default class DataTransformer {
  static getDietType(id) {
    switch (id) {
      case "1":
        return "veg";
      case "2":
        return "non-veg";
      case "3":
        return "egg";
      default:
        return "NA";
    }
  }

  static getCatalogue(data, sortBy = null, filters = []) {
    const categoryResponse = clone(data.getAllCategories);
    const optionsData = clone(data.getSortFilters);

    categoryResponse.forEach((category) => {
      category.items = [];
      category.sub_categories &&
        category.sub_categories.forEach((sub_category) => {
          sub_category.items = [];
        });
    });

    return {
      categories: categoryResponse,
      options: optionsData,
      appliedOptions: { sort_by: sortBy, filters: filters },
    };
  }

  static getSearchResults(items) {
    const transformedData = items.map((item) => {
      return new Item(item, false, false, true, false, false);
    });

    const sortedData = transformedData.sort((a, b) => {
      if (a.current_stock === 0 && b.current_stock !== 0) {
        return 1;
      } else if (b.current_stock === 0 && a.current_stock !== 0) {
        return -1;
      }

      return a.sort_order - b.sort_order;
    });

    return sortedData;
  }

  static sortItems = (items) => {
    let data = [];
    let outOfStock = [];
    items.forEach((item) => {
      if (item.current_stock === 0) {
        outOfStock.push(item);
      } else {
        data.push(item);
      }
    });

    data.sort((a, b) => a.sort_order - b.sort_order);

    return [...data, ...outOfStock];
  };
}
