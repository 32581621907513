import React, { useRef, useState, useEffect, lazy, Suspense } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";

import useSize from "@react-hook/size";
import { bindActionCreators } from "redux";
import WebFont from "webfontloader";

import CurrencyHelper from "common/helpers/currency.helper";
import getFilterTitleArr from "common/helpers/filters";
import ResponsiveHelper from "common/helpers/responsive.helper";
import analyticsPubSub, {
  ANALYTICS_EVENTS,
} from "common/services/analyticsPubSub";
import deliveryTimeService from "common/services/deliveryTime/deliveryTimeService";
import freshChatService from "common/services/freshChat";
import upsdkService from "common/services/upsdkService";
import {
  userLogin,
  userLogout,
  guestUserLogin,
} from "common/store/actions/auth";
import fetchCatalogue from "common/store/actions/catalogue";
import {
  clearSearch,
  fetchSearch,
  fetchOrderSearch,
  clearOrderSearch,
} from "common/store/actions/search";
import { setSelectedAddress } from "common/store/actions/ui";
import { setLastOrderDetails } from "common/store/actions/order";
import configSelector from "common/store/selectors/configSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import { BaseProvider } from "../../context/BaseContext";
import { DialogProvider } from "../../context/DialogContext";
import { showNotification } from "../../helpers/utils";
import ScrollToTop from "../../hooks/scrollToTop.hook";
import { translateOptions } from "../../i18n";
import Footer from "../footer/index.component";
import Header from "../header/index.component";
import pageSelector from "common/store/selectors/pageSelector";

import i18next from "i18next";
import ScreenHelper from "../../helpers/screen.helper";
import GTMService from "common/services/GTMService";
import useRouteHook from "../../hooks/useRoute.hook";
import AddressFlow from "../address/index.component";

import "dayjs/locale/es";
import "./index.component.scss";

const SideBar = lazy(() =>
  import(
    "../side-bar/index.component" /* webpackChunkName: "Sidebar", webpackPrefetch: true */
  )
);

const AppToast = lazy(() =>
  import(
    "../toast/index.component" /* webpackChunkName: "AppToast", webpackPrefetch: true */
  )
);

const ItemCustomization = lazy(() =>
  import(
    "../item-customization/index.component" /* webpackChunkName: "ItemCustomization", webpackPrefetch: true */
  )
);

const NpsFeedback = lazy(() =>
  import(
    "../nps-feedback/index.component" /* webpackChunkName: "NpsFeedback", webpackPreload: true */
  )
);

const Navigator = lazy(() =>
  import(
    "../navigator/index.component" /* webpackChunkName: "Navigator", webpackPreload: true */
  )
);

const AuthDialog = lazy(() =>
  import(
    "../../views/auth-flow/index.component" /* webpackChunkName: "AuthDialog", webpackPreload: true */
  )
);

function BaseLayout(props) {
  const isQrModeEnabled = false;
  const componentRef = useRef();
  const location = useLocation();
  const [width] = useSize(componentRef);

  const [sortBy, setSortBy] = useState(null);
  const [filterBy, setFilterBy] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [isDrawerOpen, setDrawer] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [authResponse, setAuthResponse] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAuthDialogOpen, setAuthDialog] = useState(false);
  const [activeAuthView, setAuthView] = useState(undefined);
  const [activeAddressView, setActiveAddressView] = useState(null);
  const [activeAuthFlow, setActiveAuthFlow] = useState(null);
  const [isChangePassword, setChangePassword] = useState(false);
  const [openItemCustomization, setItemCustomization] = useState(false);
  const [authDialogProps, setAuthDialogProps] = useState({});
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [restrictedAccess, setRestrictedAccess] = useState(false);
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const activeLanguage = i18next.language;
  const { i18n } = useTranslation();
  const { historyPush } = useRouteHook();
  document.body.dir = i18n.dir();

  // callbacks
  const {
    fetchCatalogue,
    userLogin,
    userLogout,
    guestLogin,
    getCurrentFallbackHandler,
    allRoutes,
    setCurrentFallback,
    privateRoutes,
  } = props;

  // props
  const {
    cart,
    config,
    upsdk,
    userData,
    authToken,
    options,
    bizInfo,
    selectedStore,
    fulfilmentType,
    lastOrderDetails,
    setLastOrderDetails,
    selectedAddress,
    setSelectedAddress,
  } = props;

  const primaryColor = configSelector.getPrimaryColor({ config });
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const hideDeliverNow = configSelector.getHideDeliverNow({ config });
  const enableTimeSlots = configSelector.getEnableTimeSlots({ config });
  const maxPreOrderDate = configSelector.getMaxPreOrderDate({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const hideDeliverNowAfterOffset = configSelector.getHideDeliverNowAfterOffset(
    { config }
  );
  const selectedFontFamily = configSelector.getFontFamily({ config });
  const npsFeedback = configSelector.getNpsFeedback({ config });
  const landingScreenConfig = pageSelector.getLandingPage({ config });
  const landingScreenEnabled = landingScreenConfig?.enabled;
  const menuRoute = landingScreenEnabled ? "/menu" : "";
  const isd_code = upsdkSelector.getISDCode({ upsdk });

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          `${selectedFontFamily}:400`,
          `${selectedFontFamily}:600`,
          `${selectedFontFamily}:700`,
          "Open Sans:400",
          "Open Sans:600",
          "Open Sans:700",
        ],
      },
    });

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (privateRoutes.includes(location.pathname) && !userData) {
      showLoginDialog({
        callback: () => {
          setRestrictedAccess(false);
        },
      });
      setRestrictedAccess(true);
    }

    const allowedPathArray = ["/checkout", "/cart", menuRoute];

    if (allowedPathArray.includes(location.pathname)) {
      if (selectedStore) {
        const { lat, lng } = selectedStore;
        upsdkService.refreshSelectedStore(lat, lng, fulfilmentType);
      }
    }

    // setPreviousPath(location.pathname);

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const currency = upsdkSelector.getCurrency({ upsdk });
    CurrencyHelper.setCurrency(currency);
    GTMService.setCurrency(currency);
  }, [upsdk]);

  useEffect(() => {
    if (bizInfo && selectedStore && fulfilmentType) {
      deliveryTimeService.init({
        bizObject: bizInfo,
        fulfilmentType,
        storeObject: selectedStore,
        hideDeliverNow,
        hideDeliverNowAfterOffset,
        enableTimeSlots,
        maxPreOrderDate,
      });
    }

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilmentType, bizInfo, selectedStore]);

  // the selectedStore object might change even if the store itself haven't
  // changed. relying on the id reduces effect triggers unneccessarily
  const selectedStoreId = selectedStore ? selectedStore.id : null;

  useEffect(() => {
    if (lastOrderDetails) {
      if (lastOrderDetails.feedbackComplete) {
        return;
      }
      const orderId = lastOrderDetails.order_id;
      upsdkService
        .getOrderDetails(orderId)
        .then((response) => {
          const statusUpdates = response.order.status_updates;
          const statusComplete = statusUpdates.find(
            (item) => item.status === "Completed"
          );
          if (statusComplete) {
            setFeedbackOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [lastOrderDetails]);

  useEffect(() => {
    if (selectedAddress) {
      upsdkService.setDeliveryAddress(selectedAddress);
    } else {
      upsdkService.removeDeliveryAddress();
    }
  }, [selectedAddress]);

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
    setLastOrderDetails({ ...lastOrderDetails, feedbackComplete: true });
  };

  const handleFeedbackSubmission = (feedback) => {
    const payload = {
      name: userData.name,
      order_id: lastOrderDetails.order_id,
      rating: feedback.selectedRating,
      comments: feedback.comments,
      choice_text: feedback.choiceText,
    };

    upsdkService
      .submitFeedback(payload)
      .then(() => {
        setLastOrderDetails({ ...lastOrderDetails, feedbackComplete: true });
      })
      .catch((error) => console.log(error))
      .finally(() => setFeedbackOpen(false));
  };

  useEffect(() => {
    upsdkService.updateCartOnLanguageChange();
  }, [activeLanguage]);

  useEffect(() => {
    fetchCatalogue(sortBy, filterBy, fulfilmentType);
  }, [
    sortBy,
    filterBy,
    selectedStoreId,
    activeLanguage,
    fetchCatalogue,
    fulfilmentType,
  ]);

  useEffect(() => {
    if (!authToken) return;
    upsdkService.setAuthHeader(authToken);
    upsdkService.getUserDetails().then((response) => {
      userLogin(response.data);
    });

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    if (userData) {
      freshChatService.updateInfo({
        firstName: userData.first_name,
        lastName: userData.last_name,
        phone: userData.phone,
        email: userData.email,
      });
    }
  }, [userData]);

  const toggleDrawer = () => {
    setDrawer(!isDrawerOpen);
  };

  const showAuthDialog = (authView, authProps = {}) => {
    if (authProps instanceof Object) {
      setAuthDialogProps(authProps);
    }
    setAuthView(authView);
    setAuthDialog(true);
  };

  const hideAuthDialog = (isLoggedIn) => {
    setAuthDialogProps({});
    setAuthDialog(false);
    if (privateRoutes.includes(location.pathname) && !(isLoggedIn === true)) {
      historyPush(menuRoute);
    }
    setRestrictedAccess(false);
  };

  const toggleChangePassword = () => {
    setChangePassword(!isChangePassword);
  };

  const handleSetView = (viewToShow) => {
    setAuthView(viewToShow);
  };

  const handleContinueAsGuest = (viewToShow) => {
    handleSetView(viewToShow);
  };

  const handleResendOtpClick = (phone) => {
    if (activeAuthFlow === "social") {
      return upsdkService.resendOTP(phone).catch((e) => {
        showNotification(e.message, "error");
        throw e;
      });
    } else {
      return upsdkService.login(isd_code, phone).catch((e) => {
        showNotification(e.message, "error");
        throw e;
      });
    }
  };

  const handleFulfilmentClick = (type) => {
    upsdkService.setFulfilmentType(type.key);
  };

  const sortByOnChange = (data) => {
    const eventObj = {
      sort: data,
    };
    setSortBy(data);
    analyticsPubSub.publish(ANALYTICS_EVENTS.SORT_APPLIED, eventObj);
  };

  const filterByOnChange = (data) => {
    const filterValues = getFilterTitleArr(data, options.filters);
    const eventObj = {
      filter_tags: filterValues.join(),
    };
    setFilterBy(data);
    analyticsPubSub.publish(ANALYTICS_EVENTS.FILTER_APPLIED, eventObj);
  };

  const handleUserLoginSuccess = (data) => {
    if (authDialogProps && authDialogProps.callback instanceof Function) {
      authDialogProps.callback();
    }
    hideAuthDialog(true);
    userLogin(data);
    upsdkService.removeDeliveryAddress();
    setSelectedAddress(null);
  };

  const userSocialLogin = (data) => {
    userLogin(data);
    upsdkService.removeDeliveryAddress();
    setSelectedAddress(null);
  };

  const handleSocialLoginSuccess = (userSession) => {
    const { message } = userSession;
    switch (message) {
      case "phone_number_required":
        setAuthView("phoneVerify");
        setAuthResponse(userSession);
        break;
      case "userbiz_phone_not_validated":
        setAuthView("phoneVerify");
        setAuthResponse(userSession);
        break;
      default:
        userSocialLogin(userSession);
        hideAuthDialog(true);
    }
  };

  const handleUserLoginFailure = (data) => {
    handleUserLogout();
  };

  const handleUserLogout = () => {
    if (userData && userData.phone) {
      upsdkService.logout(userData.phone);
    }
    userLogout();
    setSelectedAddress(null);
    if (privateRoutes.includes(location.pathname)) {
      historyPush(menuRoute);
      setRestrictedAccess(false);
    }
  };

  const handleUserSignupSuccess = (data) => {
    setAuthResponse({ ...data, type: "signup" });
    showAuthDialog("otpVerify");
    upsdkService.removeDeliveryAddress();
    setSelectedAddress(null);
  };

  const handleUserSignupFailure = (data = null) => {
    setAuthResponse(data);
    if (ScreenHelper.isReferralScreen(location.pathname)) {
      historyPush(menuRoute);
    }
  };

  const handlePhoneVerifyContinue = (data) => {
    setAuthResponse({ ...data, type: "phoneVerify" });
    handleSetView("otpVerify");
  };

  const toggleItemCustomization = (item) => {
    setItemCustomization(!openItemCustomization);
  };

  const showItemCustomization = (item) => {
    setItemCustomization(true);
    setSelectedItem(item);
  };

  const hideItemCustomization = () => {
    setItemCustomization(false);
    setSelectedItem(null);
  };

  const handleCustomizationComplete = (data) => {};

  const closeAddressViewFlow = () => {
    setActiveAddressView(null);
  };

  const guestCheckoutSuccessCallback = (data) => {
    if (!data) return;
    guestLogin({
      name: data.customer_name,
      email: data.customer_email,
      phone: data.customer_phone,
      ...data,
      guest_checkout: true,
    });
    upsdkService.setGuestPhone(data.customer_phone);
    if (authDialogProps && authDialogProps.callback instanceof Function) {
      authDialogProps.callback();
    }
    hideAuthDialog();
  };

  const showLoginDialog = (propData) => {
    showAuthDialog("signIn", propData);
  };

  useEffect(() => {
    if (isDrawerOpen) {
      setDrawer(false);
    }
    getCurrentFallbackHandler(
      location.pathname,
      allRoutes,
      setCurrentFallback,
      menuRoute,
      config
    );

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const items = cart.items;
    let quantity = 0;
    items.forEach((item) => {
      quantity += item.quantity;
    });
    setCartCount(quantity);
  }, [cart]);

  useEffect(() => {
    if (width) {
      setIsMobileView(ResponsiveHelper.isMobile(width));
    }
  }, [width]);

  return (
    <div className="base-layout-container" ref={componentRef}>
      <BaseProvider
        value={{
          filterBy: filterBy,
          sortBy: sortBy,
          options: options,
          isMobileView: isMobileView,
          screenWidth: width,
          sticky: sticky,
          setSticky: setSticky,
          secondaryTextColor: secondaryTextColor,
          searchActive: searchActive,
          landingScreenEnabled: landingScreenEnabled,
          menuRoute: menuRoute,
          activeAddressView: activeAddressView,
          isQrModeEnabled: isQrModeEnabled,
          showLoginDialog: (props) => showLoginDialog(props),
          userLogout: () => handleUserLogout(),
          showItemCustomization: showItemCustomization,
          hideItemCustomization: hideItemCustomization,
          handleRemoveFilters: (filters) => filterByOnChange(filters),
          showAuthDialog: showAuthDialog,
          setActiveAddressView: setActiveAddressView,
        }}
      >
        <DialogProvider>
          <Header
            {...props}
            cart={cart}
            sortBy={sortBy}
            filterBy={filterBy}
            userData={userData}
            cartCount={cartCount}
            isMobileView={isMobileView}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            customRoutes={allRoutes}
            location={location}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sortByCallback={sortByOnChange}
            filterByCallback={filterByOnChange}
            fulfilmentCallback={handleFulfilmentClick}
            toggleDrawerCallback={toggleDrawer}
            hideAuthDialogCallback={hideAuthDialog}
            toggleChangePasswordCallback={toggleChangePassword}
            setSearchActive={setSearchActive}
            showAuthDialogCallback={(authView) => showAuthDialog(authView)}
            fulfilmentType={fulfilmentType}
          />

          <Suspense fallback={null}>
            <SideBar
              {...props}
              userData={userData}
              open={isDrawerOpen}
              isMobileView={isMobileView}
              customRoutes={allRoutes}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              primaryTextColor={primaryTextColor}
              toggleDrawerCallback={toggleDrawer}
              userLogoutCallback={handleUserLogout}
              secondaryTextColor={secondaryTextColor}
              showAuthDialogCallback={(authView) => showAuthDialog(authView)}
            />

            {!restrictedAccess && (
              <>
                <ScrollToTop
                  customRoutes={allRoutes}
                  landingScreenEnabled={landingScreenEnabled}
                >
                  <div className="view-wrapper">{props.children}</div>
                </ScrollToTop>

                {!(location.pathname === "/cart" && isMobileView) &&
                  !(location.pathname === "/checkout" && isMobileView) && (
                    <Footer
                      {...props}
                      primaryColor={primaryColor}
                      secondaryTextColor={secondaryTextColor}
                      secondaryColor={secondaryColor}
                    />
                  )}
              </>
            )}
          </Suspense>

          <Suspense fallback={null}>
            <Navigator
              {...props}
              cart={cart}
              customRoutes={allRoutes}
              toggleDrawerCallback={toggleDrawer}
            />
          </Suspense>

          <Suspense fallback={null}>
            <AuthDialog
              {...props}
              {...authDialogProps}
              isMobileView={isMobileView}
              upsdk={upsdk}
              handleSetView={handleSetView}
              authResponse={authResponse}
              activeAuthView={activeAuthView}
              isAuthDialogOpen={isAuthDialogOpen}
              hideAuthDialogCallback={hideAuthDialog}
              resendOtpClickCallback={handleResendOtpClick}
              setAuthResponse={setAuthResponse}
              setActiveAuthFlow={setActiveAuthFlow}
              activeAuthFlow={activeAuthFlow}
              loginClickCallback={(loginView) => handleSetView(loginView)}
              signUpClickCallback={(signUpView) => handleSetView(signUpView)}
              userLoginSuccessCallback={(data) => handleUserLoginSuccess(data)}
              userLoginFailureCallback={(data) => handleUserLoginFailure(data)}
              userSignupSuccessCallback={(data) =>
                handleUserSignupSuccess(data)
              }
              userSignupFailureCallback={(data) =>
                handleUserSignupFailure(data)
              }
              phoneVerifyContinueCallback={(data) =>
                handlePhoneVerifyContinue(data)
              }
              socialLoginSuccessCallback={(userSession) =>
                handleSocialLoginSuccess(userSession)
              }
              socialSignUpSuccessCallback={(userSession) =>
                handleSocialLoginSuccess(userSession)
              }
              forgotPasswordClickCallback={(forgotPasswordView) =>
                handleSetView(forgotPasswordView)
              }
              guestCheckoutCallback={(guestCheckoutView) =>
                handleContinueAsGuest(guestCheckoutView)
              }
              guestCheckoutSuccessCallback={(data) =>
                guestCheckoutSuccessCallback(data)
              }
            />
          </Suspense>

          <Suspense fallback={null}>
            <ItemCustomization
              {...props}
              open={openItemCustomization}
              toggleItemCustomizationCallback={toggleItemCustomization}
              onCompleteCallback={handleCustomizationComplete}
              item={selectedItem}
              selectedStore={selectedStore}
            />
          </Suspense>

          {npsFeedback.enable && (
            <Suspense fallback={null}>
              <NpsFeedback
                open={feedbackOpen}
                bizInfo={bizInfo}
                primaryTextColor={primaryTextColor}
                primaryColor={primaryColor}
                handleClose={handleFeedbackClose}
                handleFeedbackSubmission={handleFeedbackSubmission}
              />
            </Suspense>
          )}

          <AddressFlow
            closeAddressViewFlow={closeAddressViewFlow}
            activeAddressView={activeAddressView}
          />

          <Suspense fallback={null}>
            <AppToast selectedFontFamily={selectedFontFamily} />
          </Suspense>
        </DialogProvider>
      </BaseProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    upsdk: state.upsdk,
    userData: state.auth.data,
    cart: upsdkSelector.getCart(state),
    options: state.catalogue.data ? state.catalogue.data.options : null,
    authToken: upsdkSelector.getAuthHeader(state),
    subLocality: state.ui.subLocality,
    selectedAddress: state.ui.selectedAddress,
    fulfilmentType: upsdkSelector.getFullfilment(state),
    bizInfo: upsdkSelector.getBizInfo(state),
    selectedStore: upsdkSelector.getStore(state),
    lastOrderDetails: state.order.lastOrder,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSearch: fetchSearch,
      fetchOrderSearch: fetchOrderSearch,
      clearOrderSearch: clearOrderSearch,
      clearSearch: clearSearch,
      fetchCatalogue: fetchCatalogue,
      userLogin: userLogin,
      userLogout: userLogout,
      guestLogin: guestUserLogin,
      setLastOrderDetails: setLastOrderDetails,
      setSelectedAddress: setSelectedAddress,
    },
    dispatch
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions
  )(connect(mapStateToProps, mapDispatchToProps)(BaseLayout))
);
