import React, { useEffect, useState } from "react";

import { Dialog } from "@material-ui/core";

import storeStatus from "common/helpers/storeHelper";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import CrossIcon from "../../assets/icons/cross.icon";
import AppButton from "../button/index.component";
import Typography from "../typography/index.component";
import ScreenHelper from "../../helpers/screen.helper";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import "./index.component.scss";

function ServiceableErrorPrompt({ upsdk, subLocality, primaryColor }) {
  const [status, setStatus] = useState({ show: false, message: "" });
  const address = upsdkSelector.getAddress({ upsdk });
  const store = upsdkSelector.getStore({ upsdk });
  const bizInfo = upsdkSelector.getBizInfo({ upsdk });
  const fulfillment_mode = upsdkSelector.getFullfilment({ upsdk });
  const { t } = useTranslation();
  const location = useLocation();

  // variables
  const pathname = location.pathname;

  useEffect(() => {
    let message = "";
    if (!store && (address || subLocality)) {
      message =
        (bizInfo && bizInfo.msg_no_stores_nearby) ||
        t("storeLocator.notStoresNearBy");
      setStatus({
        show: true,
        title: t("header.storeNotDeliverableHeading"),
        message: message,
      });
    } else if (store) {
      let sStatus = storeStatus(store, bizInfo, fulfillment_mode, t);
      if (!sStatus) return;
      const { title, message, futureOrders, closed } = sStatus;
      if (closed && !futureOrders) {
        setStatus({
          show: true,
          title: title || t("storeLocator.storeClosed"),
          message: message,
        });
      } else if (
        closed &&
        futureOrders &&
        ScreenHelper.isCheckoutScreen(pathname)
      ) {
        if (closed) {
          setStatus({
            show: true,
            title: title || t("storeLocator.storeClosed"),
            message: message,
          });
        }
      } else {
        setStatus({ show: false });
      }
    }

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subLocality, store]);

  const handleClose = () => {
    setStatus({ show: false });
  };

  return (
    <Dialog
      keepMounted
      open={status.show}
      onClose={handleClose}
      classes={{
        root: "confirmation-dialog-root",
        container: "confirmation-dialog-container",
        paper: "confirmation-dialog-paper",
      }}
      disableScrollLock={true}
    >
      <div className="confirmation-form">
        <div className="dialog-header">
          <Typography
            variant="h1"
            weight="bold"
            className="form-heading"
            fontColor="#182135"
          >
            {status.title}
          </Typography>
          <CrossIcon
            fill="#A3A3A4"
            size={{ width: 16, heigth: 16 }}
            className="close-dialog-icon"
            onClickCallback={handleClose}
          />
        </div>
        <div className="dialog-content">
          <Typography
            variant="h3"
            weight="regular"
            className="confirmation-text"
            fontColor="#626264"
          >
            {status.message}
          </Typography>
        </div>
        <div className="dialog-footer">
          <AppButton
            fullWidth
            variant="contained"
            className="confirm-button"
            isLoading={false}
            onClickCallback={handleClose}
            buttonColor={primaryColor}
          >
            <Typography variant="h3" weight="bold" fontColor="#FFFFFF">
              {t("common.ok")}
            </Typography>
          </AppButton>
        </div>
      </div>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    upsdk: state.upsdk,
    subLocality: state.ui.subLocality,
  };
}

export default connect(mapStateToProps, null)(ServiceableErrorPrompt);
