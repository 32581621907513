import graphqlService from "../../services/graphql/graphqlService";
import upsdkService from "../../services/upsdkService";

// catalogue search
export const FETCH_SEARCH_PENDING = "FETCH_SEARCH_PENDING";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_ERROR = "FETCH_SEARCH_ERROR";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

// order search
export const FETCH_ORDER_SEARCH_PENDING = "FETCH_ORDER_SEARCH_PENDING";
export const FETCH_ORDER_SEARCH_SUCCESS = "FETCH_ORDER_SEARCH_SUCCESS";
export const FETCH_ORDER_SEARCH_ERROR = "FETCH_ORDER_SEARCH_ERROR";
export const CLEAR_ORDER_SEARCH = "CLEAR_ORDER_SEARCH";

// catalogue search
function fetchSearchPending() {
  return {
    type: FETCH_SEARCH_PENDING,
  };
}
function fetchSearchSuccess(catalogue) {
  return {
    type: FETCH_SEARCH_SUCCESS,
    payload: catalogue,
  };
}
function fetchSearchError(error) {
  return {
    type: FETCH_SEARCH_ERROR,
    error: error,
  };
}
function fetchClearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

// order search
function fetchOrderSearchPending() {
  return {
    type: FETCH_ORDER_SEARCH_PENDING,
  };
}
function fetchOrderSearchSuccess(catalogue) {
  return {
    type: FETCH_ORDER_SEARCH_SUCCESS,
    payload: catalogue,
  };
}
function fetchOrderSearchError(error) {
  return {
    type: FETCH_ORDER_SEARCH_ERROR,
    error: error,
  };
}
function fetchClearOrderSearch() {
  return {
    type: CLEAR_ORDER_SEARCH,
  };
}

// catalogue search
export function fetchSearch(keyword = null, fulfillmentType = "delivery") {
  return (dispatch) => {
    dispatch(fetchSearchPending());
    graphqlService
      .searchItems(keyword, fulfillmentType)
      .then((response) => {
        dispatch(fetchSearchSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchSearchError(error));
      });
  };
}

export function clearSearch() {
  return (dispatch) => {
    dispatch(fetchClearSearch());
  };
}

// order search
export function fetchOrderSearch() {
  return (dispatch) => {
    dispatch(fetchOrderSearchPending());
    upsdkService
      .getOrderHistory()
      .then((response) => {
        dispatch(fetchOrderSearchSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchOrderSearchError(error));
      });
  };
}
export function clearOrderSearch() {
  return (dispatch) => {
    dispatch(fetchClearOrderSearch());
  };
}
