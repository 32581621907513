import React from "react";

import { COLOR_SPUN_PEARL } from "../../../constants/colors.constants";
import ChevronIcon from "../../../assets/icons/chevron.icon";
import Typography from "../../typography/index.component";
import { useTranslation } from "react-i18next";

const SectionTitle = ({ category, secondaryTextColor, primaryTextColor }) => {
  const { name, main_category_name, is_sub_category, item_count } = category;

  const { t } = useTranslation();

  if (!is_sub_category) {
    return (
      <div className="section-heading-wrapper">
        <Typography
          variant="h1"
          weight="bold"
          className="section-heading"
          fontColor={primaryTextColor}
        >
          {name}
        </Typography>
        <Typography variant="h1" className="section-heading-desc" weight="bold">
          {t("common.totalItems", { total: item_count })}
        </Typography>
      </div>
    );
  }

  return (
    <div className="section-heading-wrapper">
      <Typography
        variant="h1"
        weight="bold"
        className="section-heading"
        fontColor={primaryTextColor}
      >
        {main_category_name}
      </Typography>
      <ChevronIcon
        fill={COLOR_SPUN_PEARL}
        className="breadcrumb-chevron"
        size={{ width: 5, height: 5 }}
      />
      <Typography
        variant="h1"
        weight="bold"
        className="section-heading"
        fontColor={secondaryTextColor}
      >
        {`${name} (${item_count})`}
      </Typography>
    </div>
  );
};

export default SectionTitle;
