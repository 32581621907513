import { client } from ".";
import DataTransformer from "../../helpers/dataTransformer";
import CATALOGUE from "./queries/catalogue.query";
import BIZ from "./queries/biz.query";
import Item from "../../store/models/Item";
import analyticsPubSub, { ANALYTICS_EVENTS } from "../analyticsPubSub";

function getCatalogue(sort_by, filter_by, fulfilment_mode) {
  return client
    .query({
      query: CATALOGUE.GET_CATALOGUE,
      variables: {
        sort_by,
        filter_by,
        fulfilment_mode,
      },
    })
    .then((res) => {
      return DataTransformer.getCatalogue(res.data, sort_by, filter_by);
    })
    .catch((error) => {
      throw error;
    });
}

function getItemOptions(item_id) {
  return client
    .query({
      query: CATALOGUE.GET_ITEM_OPTIONS,
      variables: {
        item_id: item_id,
      },
    })
    .then((res) => {
      return res.data.getItemOptionGroups;
    })
    .catch((error) => {
      throw error;
    });
}

function getQrModeBizInfo() {
  return client
    .query({
      query: BIZ.GET_BIZ_INFO,
    })
    .then((res) => {
      return res.data.getBusinessInfo;
    })
    .catch((error) => {
      throw error;
    });
}

function searchItems(keyword, fulfilment_mode) {
  return client
    .query({
      query: CATALOGUE.GET_ALL_ITEMS,
      variables: {
        keyword,
        fulfilment_mode,
      },
    })
    .then((res) => {
      return DataTransformer.getSearchResults(res.data.getAllItems);
    })
    .catch((error) => {
      throw error;
    });
}

function getItemById(item_id) {
  return client
    .query({
      query: CATALOGUE.GET_ITEM_BY_ID,
      variables: {
        item_id: item_id,
      },
    })
    .then((res) => {
      const item = new Item(res.data.getItemById);
      const eventObj = {
        item_id: item.id,
        item_title: item.name,
        item_price: item.price,
        item_category: item.category ? item.category.name : null,
        item_thumbnail: item.thumbnail,
      };
      analyticsPubSub.publish(ANALYTICS_EVENTS.PRODUCT_CLICKED, eventObj);

      return item;
    })
    .catch((error) => {
      throw error;
    });
}

function getCategoryById(category_id, sort_by, filter_by, fulfilment_mode) {
  return client
    .query({
      query: CATALOGUE.GET_CATEGORY_BY_ID,
      variables: {
        category_id,
        filter_by,
        fulfilment_mode,
      },
    })
    .then((res) => {
      const items = res.data.getCategory.items.map((item) => new Item(item));
      if (sort_by) {
        return DataTransformer.sortItems(items);
      }
      return items;
    })
    .catch((error) => {
      throw error;
    });
}

export default {
  getCatalogue,
  getItemOptions,
  getQrModeBizInfo,
  searchItems,
  getItemById,
  getCategoryById,
};
